import { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import reducer from "./reducer";
import { parseSessionStorageItemIfPresent } from "utils";

const BorrowerContext = createContext();

const BorrowerProvider = ({
  children,
  initialState = {
    borrowers: [{ id: "primaryBorrower" }],
  },
  sessionStorageKey,
}) => {
  // Either get state from sessionStorage, or fallback to the provided initialState
  initialState =
    parseSessionStorageItemIfPresent(sessionStorageKey) || initialState;

  const [state, dispatch] = useReducer(reducer, initialState);

  // Set sessionStorage
  sessionStorage[sessionStorageKey] = JSON.stringify(state);

  return (
    <BorrowerContext.Provider value={{ state, dispatch, sessionStorageKey }}>
      {children}
    </BorrowerContext.Provider>
  );
};

BorrowerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.object,
  sessionStorageKey: PropTypes.string,
};

const useBorrower = () => {
  const context = useContext(BorrowerContext);

  if (context === undefined) {
    throw new Error("useBorrower must be used within a BorrowerProvider");
  }

  return context;
};

export { BorrowerProvider as default, BorrowerContext, useBorrower };
