import * as icons from "lib/icons";

export const META_TITLE_FALLBACK = "Canada Life Home Finance KFI Generator";

export const API_ROUTES = {
  dashboard: {
    applications: "/dashboard/applications",
    applicationSummary: "/dashboard/application_summary",
    blogData: "/dashboard/blog_promo",
  },
  clientOverview: "/clients",
  loginTokens: "/login_tokens",
  mortgageApplications: "/mortgage_applications",
  oauth: {
    token: "/oauth/token",
    authorise: "/oauth/authorize",
    applications: "/oauth/applications",
  },
  auth: {
    authenticate: "/auth/authenticate",
  },
  postcodes: {
    lendingCriteriaCheck: "/postcodes/lending_criteria_check",
  },
  settings: "/settings",
};

// prettier-ignore
export const EXTERNAL_LINKS = {
  register: "https://www.canadalife.co.uk/home-finance/home-finance-kfi-application-portal/register",
  faqs: "https://www.canadalife.co.uk/home-finance/home-finance-kfi-application-portal/what-s-new",
  contact: "https://www.canadalife.co.uk/contact-us/",
  adviserSupport: "https://www.canadalife.co.uk/adviser-support/",
  login: "https://www.canadalife.co.uk/login/",
  lendingCriteria: "https://www.canadalife.co.uk/document-library/?Solutions[]=Home%20Finance&keyword=lending&includeAdviser=true&limit=15&offset=0",
  valuationReviewRequest: "https://documents.canadalife.co.uk/valuation-review-request.pdf",
  applicationFormInitialAdvance: "https://documents.canadalife.co.uk/application-form-initial-advance.pdf",
  equityReleaseCustomerGuide: "https://documents.canadalife.co.uk/equity-release-explained.pdf",
  termsAndConditions: "https://www.canadalife.co.uk/document-library/?Solutions[]=Home%20Finance&keyword=terms&limit=20&offset=0",
  weCareCustomerGuide: "https://www2.canadalife.co.uk/l/93102/2022-02-28/76gg73",
};

export const EMAILS = {
  adviserSupport: "hf-applications@canadalife.co.uk",
};

// prettier-ignore
export const BREAKPOINTS = {
  "xs":   320,
  "s":    375,
  "m":    768,
  "l":    1024,
  "xl":   1200,

  // Tweakpoints
  ">s":   376,
  "<l":   1023,
};

export const ICONS = Object.keys(icons).filter((icon) => icon !== "default");

export const ADVISER_WITHOUT_PERMISSION =
  "You do not have permission to access this data";
export const CASH_RESERVE_CANNOT_BE_MAX =
  "Maximum cash reserve is not allowed when initital advance is maximum";
export const POSTCODE_NOT_FOUND = "Postcode not found";
export const POSTCODE_OUTSIDE_OF_LENDING_CRITERIA =
  "Postcode outside of lending criteria";

export const DATE_FORMAT = "YYYY-MM-DD";
export const CL_DATE_FORMAT = "DD/MM/YYYY";
export const SHORT_DATE_FORMAT = "DD MMM YYYY";

export const REMAINING_LEASE_BASE = 155;
export const REMAINING_LEASE_MAX = 75;

export const BORROWER_TOO_OLD = "tooOld";
export const BORROWER_TOO_YOUNG = "tooYoung";
export const ANB_UPPER_LIMIT = 90;

export const MIN_PASSWORD_LENGTH = 8;
export const RESET_PASSWORD = "reset";
export const ACCEPT_INVITATION = "invitation";
export const COMPLEX_PASSWORD_MESSAGE = `Must be ${MIN_PASSWORD_LENGTH} characters or longer. Must have one number, one symbol, one upper case character, and one lower case character. Do not use repeating patterns of three or more characters e.g. AAA, aaa, 111, or &&&`;

export const ILLUSTRATION_DETAILS_TYPE_REPLACEMENT = "Replacement";
export const ILLUSTRATION_DETAILS_TYPE_AMENDMENT = "Amendment";
export const DRAFT = "Draft";
export const ACTIVE = "Active";
export const DASHBOARD_APPLICATION_STATUSES = [
  ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
  ILLUSTRATION_DETAILS_TYPE_REPLACEMENT,
  DRAFT,
  ACTIVE,
];

export const ADVISER_DETAILS_TYPE_UPDATE = "edit";
export const ADVISER_DETAILS_TYPE_NEW = "new";
export const ADVISER_DETAILS_TYPE_NEW_CLIENT_EDIT = "newClientEdit";

export const CLIENT_DETAILS_TYPE_UPDATE = "edit";
export const CLIENT_DETAILS_TYPE_NEW = "new";

export const PROPERTY_DETAILS_TYPE_UPDATE = "edit";
export const PROPERTY_DETAILS_TYPE_NEW = "new";

export const LOAN_DETAILS_TYPE_UPDATE = "edit";
export const LOAN_DETAILS_TYPE_NEW = "new";

export const NEW_CLIENT_SESSION_STORAGE_KEYS = [
  "exLocalAuthority",
  "postcode",
  "clubId",
  "clientDetails",
  "propertyType",
  "propertyValue",
  "tenure",
  "shelteredAccommodation",
  "independentValuation",
  "remainingLease",
  "estimatedPropertyValue",
  "initialAdvanceAmount",
  "cashReserve",
];

export const UNSUCCESSFUL_STAGES = [14, 15];
export const SUCCESSFUL_STAGES = [13, 16];
export const REQUIRES_ACTION_STAGES = [10, 17, 18];
export const ACTIVE_STAGES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12];
export const DEFAULT_STAGES = [1, 5, 7, 11, 13];

export const STAGE_IN_PROGRESS = "inProgress";
export const STAGE_REQUIRES_ACTION = "requiresAction";
export const STAGE_SUCCESSFUL = "completed";
export const STAGE_UNSUCCESSFUL = "unsuccessful";
export const STAGE_NOT_STARTED = "notStarted";

export const MAXIMUM_AMOUNT_AVAILABLE = "maximum";
export const SPECIFIC_AMOUNT = "specific";
export const ZERO_AMOUNT = 0;
export const NONE = "none";
export const MAIN_RESIDENCE_ID = 1;
export const SHELTERED_ACCOMMODATION_ID = 10;
export const AMENDMENT_APPLICATION_STAGE_ID = 10;

export const TRUTHY_STRING = "Yes";
export const FALSY_STRING = "No";

export const FORM_STORE_STATE = "state";
export const FORM_STORE_SESSION = "session";

export const COMPLETE_CLIENT = "Complete client details";
export const VIEW_APPLICATION = "View application";
export const VIEW_PRODUCTS = "View available products";

export const EXCLUSIVE_CLUB_LABEL = "Exclusive club";

// Main menu labels
export const DASHBOARD = "Dashboard";
export const CLIENTS = "Clients";
export const APPLICATIONS = "Applications";
export const FURTHER_ADVANCES = "Further advances";
export const DETAILS = "My details";
export const CHANGE_PASSWORD = "Change password";

export const OTHER_TITLES = ["Lady", "Lord", "Ms", "Professor", "Rev", "Sir"];

export const PROPERTY_VALUE_TOO_HIGH = "Property value too high";
export const PROPERTY_VALUE_MAX = 12000000;

export const NETWORK_ERROR_IDENTIFIER = "Network Error";
export const CORS_ERROR_MESSAGE =
  "We're sorry, but we are having issues logging you into your account.\nPlease email hf-applications@canadalife.co.uk with the email address of the account you are attempting to login with, and the information on the browser software you are using.\nError description: CORS Issue with connection to hf-api.canadalife.co.uk";

export const TIMEOUT_VALUE_MS = 14000;
export const TIMEOUT_ERROR_IDENTIFIER = "ECONNABORTED";
export const TIMEOUT_ERROR_MESSAGE =
  "We're sorry, but we're having difficulty connecting to the Canada Life servers.\nPlease email hf-applications@canadalife.co.uk with the email address of the account you are attempting to login with, and the information on the browser software you are using.\nError description: Network connection issue accessing hf-api.canadalife.co.uk";
