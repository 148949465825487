import { createContext, useContext, useReducer, useState } from "react";
import PropTypes from "prop-types";
import reducer from "./reducer";

const StoreContext = createContext();

const StoreProvider = (props) => {
  const {
    children,
    initialState = {
      menuIsOpen: false,
    },
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(initialState.menuIsOpen);

  const [state, dispatch] = useReducer(reducer, {
    menuIsOpen,
    setMenuIsOpen,
    ...initialState,
  });

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.object,
};

const useStore = () => {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error("useStore must be used within a StoreProvider");
  }

  return context;
};

export { StoreProvider as default, StoreContext, useStore };
