import Link from "next/link";
import {
  BORROWER_TOO_YOUNG,
  BORROWER_TOO_OLD,
  DATE_FORMAT,
  SHORT_DATE_FORMAT,
  NEW_CLIENT_SESSION_STORAGE_KEYS,
  FALSY_STRING,
  TRUTHY_STRING,
  ZERO_AMOUNT,
  ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
  ILLUSTRATION_DETAILS_TYPE_REPLACEMENT,
  AMENDMENT_APPLICATION_STAGE_ID,
  REQUIRES_ACTION_STAGES,
} from "lib/constants";
import * as utils from ".";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);
dayjs.tz.setDefault("Europe/London");

export { dayjs };

export const isExternalLink = (string) => {
  const HTTP_HTTPS_REGEX = /^https?:\/\//i;
  const V1_REDIRECT_REGEX = /\/v1-redirect/i;

  return HTTP_HTTPS_REGEX.test(string) || V1_REDIRECT_REGEX.test(string);
};

export const formatCurrency = (value, { withDecimal = true } = {}) => {
  const localeOpts = withDecimal && {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const asString = String(value);
  const withoutCommas = asString.replace(/,/g, "");
  const asNumber = withDecimal
    ? Number(withoutCommas)
    : parseInt(withoutCommas);
  const formattedString = asNumber.toLocaleString(undefined, localeOpts);

  if (isNaN(asNumber)) return value;

  return formattedString;
};

export const displayAmountWithCurrency = (amount, withDecimal = false) => {
  if (amount == null) return;
  return `£${formatCurrency(amount, { withDecimal })}`;
};

export const parseCurrency = (value) => {
  if (!value || typeof value === "number") return value;

  const withoutCommas = value.replace(/,/g, "");
  const asNumber = parseFloat(withoutCommas);

  return asNumber;
};

export const parseAmountWithCurrency = (value) => {
  const amount = value.replace("£", "");
  return parseCurrency(amount);
};

export const isWithinOpeningHours = () => {
  const currentHour = utils.dayjs.tz().hour();
  return currentHour >= 9 && currentHour < 17;
};

export const addTokenToClientHeaders = (client, accessToken) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};

export const removeFalseyArrayItems = (arr) => arr.filter((item) => !!item);

export const isValidPostcode = (postcode) => {
  const REGEX = /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}/;

  return REGEX.test(postcode);
};

export const booleanToText = (value) => {
  if (typeof value !== "boolean") return false;
  return value ? TRUTHY_STRING : FALSY_STRING;
};

export const getAgeFromDate = (date) => {
  const parsedDate = dayjs(date);
  return dayjs().diff(parsedDate, "year");
};

export const getAnbFromDate = (date) => {
  const age = getAgeFromDate(date);
  return age + 1;
};

export const calculateMaxDateFromAge = ({ age, gracePeriod }) => {
  return dayjs()
    .subtract(age + 1, "year")
    .add(gracePeriod, "weeks")
    .format(DATE_FORMAT);
};

export const createQueryStringFromObject = (object) => {
  return Object.keys(object).reduce((acc, value, index) => {
    return index === 0
      ? `?${value}=${object[value]}`
      : `${acc}&${value}=${object[value]}`;
  }, "");
};

export const useValueIfInArray = (arr, value) => {
  const findFn = (option) => option.value === value;
  return arr.find(findFn) ? value : "";
};

export const parseSessionStorageFromKeysArray = (
  keys = Object.keys(sessionStorage)
) => {
  let data = {};

  keys.forEach((key) => {
    data[key] = parseSessionStorageItemIfPresent(key);
  });

  return data;
};

export const outsideAgeRange = ({ dob, max, min } = {}) => {
  if (!dob) return;

  const tooOld = min && dayjs(dob, DATE_FORMAT).isBefore(min);
  const tooYoung = max && dayjs(dob, DATE_FORMAT).isAfter(max);

  if (tooOld) return BORROWER_TOO_OLD;
  if (tooYoung) return BORROWER_TOO_YOUNG;
};

export const shortFormatDate = (date) => {
  return dayjs(date).format(SHORT_DATE_FORMAT);
};

export const calculateDaysFromDate = (date) => {
  const startDate = dayjs(date);
  const today = dayjs();
  return Math.round(dayjs.duration(today.diff(startDate)).asDays());
};

export const isNumber = (value) => typeof value === "number" && !isNaN(value);

export const youngestBorrower = (borrowers) => {
  return borrowers.sort(sortByBirthDateAscending)[0];
};

export const sortByBirthDateAscending = (a, b) => {
  if (a.date_of_birth < b.date_of_birth) {
    return 1;
  }
  if (a.date_of_birth > b.date_of_birth) {
    return -1;
  }
  return 0;
};

export const parseSessionStorageItemIfPresent = (key) => {
  return sessionStorage[key] && JSON.parse(sessionStorage[key]);
};

export { default as linkFor } from "./linkFor";
export { default as returnPath } from "./returnPath";
export { default as birthdayCalculator } from "./birthdayCalculator";

export const findYoungestBorrower = (borrowers) => {
  let youngestBorrower;

  if (borrowers.length === 1) {
    youngestBorrower = borrowers[0];
  } else {
    youngestBorrower =
      Date.parse(borrowers[0].date_of_birth || borrowers[0].dob) >=
      Date.parse(borrowers[1].date_of_birth || borrowers[1].dob)
        ? borrowers[0]
        : borrowers[1];
  }

  return youngestBorrower;
};

export const clearNewClientSessionStorageItems = () => {
  NEW_CLIENT_SESSION_STORAGE_KEYS.forEach((key) =>
    sessionStorage.removeItem(key)
  );
};

export const clearSessionStorageItemsWithString = (string) => {
  Object.keys(sessionStorage)
    .filter((key) => key.includes(string))
    .forEach((key) => sessionStorage.removeItem(key));
};

export const parseAmountToInteger = (value) => {
  if (!value) return ZERO_AMOUNT;
  if (Number.isInteger(value)) return value;

  const removeCommas = value.split(".")[0].replace(/[^\d]/g, "");
  return parseInt(removeCommas, 10);
};

export const capitaliseEachWord = (string) => {
  if (!string) return "";

  const capitalisedString = string
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.substring(1);
    })
    .join(" ");

  return capitalisedString;
};

export const statusMap = (application) => {
  const amendmenOrReplacement =
    application.mortgage_application_stage_id === AMENDMENT_APPLICATION_STAGE_ID
      ? ILLUSTRATION_DETAILS_TYPE_AMENDMENT
      : ILLUSTRATION_DETAILS_TYPE_REPLACEMENT;

  switch (application.aasm_state) {
    case "Draft":
      if (
        REQUIRES_ACTION_STAGES.includes(
          application.mortgage_application_stage_id
        )
      ) {
        return amendmenOrReplacement;
      }
      return "Draft";
    case "Amended KFI":
      return ILLUSTRATION_DETAILS_TYPE_AMENDMENT;
    case "Replacement KFI":
      return ILLUSTRATION_DETAILS_TYPE_REPLACEMENT;
    case "Submitted":
      return amendmenOrReplacement;
    case "Active":
      return "Active";
  }
};

export const linkLabelMap = (application) => {
  const amendOrReplace =
    application.mortgage_application_stage_id === AMENDMENT_APPLICATION_STAGE_ID
      ? "Amend"
      : "Replace";

  switch (application.aasm_state) {
    case "Draft":
      if (
        REQUIRES_ACTION_STAGES.includes(
          application.mortgage_application_stage_id
        )
      ) {
        return amendOrReplace;
      }
      return "Continue";
    case "Amended KFI":
      return "Amend";
    case "Replacement KFI":
      return "Replace";
    case "Submitted":
      return amendOrReplace;
  }
};

export const linkWrapper = (content, link) => {
  return <Link href={link}>{content}</Link>;
};

export const disabledActionLink = (
  aasmState,
  isAdminAdviser,
  canGenerateKfiRequests
) => {
  switch (aasmState) {
    case "Amended KFI":
      return isAdminAdviser || !canGenerateKfiRequests;

    case "Replacement KFI":
      return isAdminAdviser || !canGenerateKfiRequests;

    default:
      return false;
  }
};
