import { dayjs, getAgeFromDate } from "utils";
import { ANB_UPPER_LIMIT, DATE_FORMAT } from "lib/constants";

interface BirthdayCalculatorOptions {
  dob: string;
  today?: dayjs.Dayjs | string;
  gracePeriod?: number;
}

export const birthdayCalculator = ({
  dob,
  today = dayjs(),
  gracePeriod = 6, // in weeks
}: BirthdayCalculatorOptions) => {
  if (!dob) {
    console.error("You must provide a date of birth.");
    return null;
  }

  const parsedDob = dayjs(dob, DATE_FORMAT);
  const parsedToday = dayjs(today, DATE_FORMAT);
  const dobIsValid = parsedDob.isValid();
  const todayIsValid = parsedToday.isValid();
  const age = getAgeFromDate(dob);

  if (!dobIsValid) {
    console.error(
      `Date of birth is not valid. Expected a date in the format "${DATE_FORMAT}" but received ${dob}.`
    );
    return null;
  }

  if (!todayIsValid) {
    console.error(
      `Current date is not valid. Expected a date in the format "${DATE_FORMAT}" but received ${today}.`
    );
    return null;
  }

  if (age >= ANB_UPPER_LIMIT) return false;

  const nextBirthday = () => {
    const thisYear = parsedToday.year();
    const birthdayThisYear = parsedDob.year(thisYear);

    return birthdayThisYear.isAfter(parsedToday)
      ? birthdayThisYear
      : birthdayThisYear.add(1, "year");
  };

  return nextBirthday().isSameOrBefore(parsedToday.add(gracePeriod, "weeks"));
};

export default birthdayCalculator;
