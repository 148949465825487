import { useEffect } from "react";
import PropTypes from "prop-types";
import TagManager from "react-gtm-module";
import env from "@beam-australia/react-env";
import { SessionProvider as NextAuthProvider } from "next-auth/react";
import { StoreProvider } from "context";
import "styles/application.scss";

const Application = ({ Component, pageProps: { session, ...pageProps } }) => {
  useEffect(() => {
    if (env("GOOGLE_TAG_MANAGER_ID")) {
      TagManager.initialize({
        gtmId: env("GOOGLE_TAG_MANAGER_ID"),
      });
    }
  }, []);

  return (
    <>
      <noscript>
        Please turn JavaScript on in order to use this application
      </noscript>
      <div>
        <NextAuthProvider session={session}>
          <StoreProvider>
            <Component {...pageProps} />
          </StoreProvider>
        </NextAuthProvider>
      </div>
    </>
  );
};

Application.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.shape({
    session: PropTypes.object,
  }),
};

export default Application;
