import * as icons from "./icons";
export default icons;

export { default as ActionRequired } from "public/icons/action-required.svg";
export { default as AddCircle } from "public/icons/add-circle.svg";
export { default as Add } from "public/icons/add.svg";
export { default as AirSourcing } from "public/icons/air-sourcing.svg";
export { default as Apply } from "public/icons/apply.svg";
export { default as ArrowBack } from "public/icons/arrow-back.svg";
export { default as ArrowDropDown } from "public/icons/arrow-drop-down.svg";
export { default as ArrowDropUp } from "public/icons/arrow-drop-up.svg";
export { default as ArrowForward } from "public/icons/arrow-forward.svg";
export { default as ArrowUpCircleFilled } from "public/icons/arrow-up-circle-filled.svg";
export { default as BestProduct } from "public/icons/best-product.svg";
export { default as Calendar } from "public/icons/calendar.svg";
export { default as CanadaLifeMainLogo } from "public/icons/cl-main-logo.svg";
export { default as CanadaLifeStackedLogo } from "public/icons/cl-stacked-logo.svg";
export { default as ChevronDown } from "public/icons/chevron-down.svg";
export { default as ChevronLeft } from "public/icons/chevron-left.svg";
export { default as ChevronUp } from "public/icons/chevron-up.svg";
export { default as ChevronRight } from "public/icons/chevron-right.svg";
export { default as ClearSearch } from "public/icons/clear-search.svg";
export { default as CloseCircleIcon } from "public/icons/close-circle-icon.svg";
export { default as CloseCircleIconFilled } from "public/icons/close-circle-icon-filled.svg";
export { default as CloseCircleIconFilledOutlined } from "public/icons/close-circle-icon-filled-outlined.svg";
export { default as Close } from "public/icons/close.svg";
export { default as Delete } from "public/icons/delete.svg";
export { default as Document } from "public/icons/document.svg";
export { default as Edit } from "public/icons/edit.svg";
export { default as Email } from "public/icons/email.svg";
export { default as EmptySearch } from "public/icons/empty-search.svg";
export { default as Error } from "public/icons/error.svg";
export { default as FileDownload } from "public/icons/file-download.svg";
export { default as Filter } from "public/icons/filter.svg";
export { default as Flag } from "public/icons/flag.svg";
export { default as Help } from "public/icons/help.svg";
export { default as InProgress } from "public/icons/in-progress.svg";
export { default as InfoCircle } from "public/icons/info-circle.svg";
export { default as Life } from "public/icons/life.svg";
export { default as Lock } from "public/icons/lock.svg";
export { default as LogOut } from "public/icons/log-out.svg";
export { default as Menu } from "public/icons/menu.svg";
export { default as MoreHoriz } from "public/icons/more-horiz.svg";
export { default as MoreVert } from "public/icons/more-vert.svg";
export { default as NoLink } from "public/icons/no-link.svg";
export { default as NoticeIconInfo } from "public/icons/notice-icon-info.svg";
export { default as NoticeIconWarning } from "public/icons/notice-icon-warning.svg";
export { default as OutboundLink } from "public/icons/outbound-link.svg";
export { default as Phone } from "public/icons/phone.svg";
export { default as Search } from "public/icons/search.svg";
export { default as Server } from "public/icons/server.svg";
export { default as Sort } from "public/icons/sort.svg";
export { default as Step } from "public/icons/step.svg";
export { default as TickCricleIconFilled } from "public/icons/tick-cricle-icon-filled.svg";
export { default as TickCricleIconOutlined } from "public/icons/tick-cricle-icon-outlined.svg";
export { default as Tick } from "public/icons/tick.svg";
export { default as Time } from "public/icons/time.svg";
export { default as User } from "public/icons/user.svg";
export { default as VisibilityOff } from "public/icons/visibility-off.svg";
export { default as Visibility } from "public/icons/visibility.svg";
export { default as WarningIconFilled } from "public/icons/warning-icon-filled.svg";
export { default as WarningIconOutlined } from "public/icons/warning-icon-outlined.svg";
