import env from "@beam-australia/react-env";
import {
  ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
  ILLUSTRATION_DETAILS_TYPE_REPLACEMENT,
  LOAN_DETAILS_TYPE_UPDATE,
  LOAN_DETAILS_TYPE_NEW,
  PROPERTY_DETAILS_TYPE_UPDATE,
  PROPERTY_DETAILS_TYPE_NEW,
  CLIENT_DETAILS_TYPE_UPDATE,
  CLIENT_DETAILS_TYPE_NEW,
  ADVISER_DETAILS_TYPE_UPDATE,
  ADVISER_DETAILS_TYPE_NEW,
} from "lib/constants";

const toV1 = (path: string) => `/v1-redirect?path=${encodeURIComponent(path)}`;

const linksMap = {
  adviserDetails: ({ clientId, type }: { clientId: string; type: string }) => {
    if (!clientId) {
      return `/new-client/adviser-details`;
    }

    switch (type) {
      case ADVISER_DETAILS_TYPE_UPDATE:
        return `/clients/${clientId}/adviser-details`;

      case ADVISER_DETAILS_TYPE_NEW:
        return `/new-client/${clientId}/adviser-details`;
    }
  },
  application: ({
    applicationFormId,
    id,
    referenceNo,
    status,
  }: {
    applicationFormId: string;
    id: string;
    referenceNo: string;
    status: string;
  }) => {
    switch (status) {
      case ILLUSTRATION_DETAILS_TYPE_AMENDMENT:
        return `/illustration-amendment/${referenceNo}`;

      case "Draft":
        return toV1(`/application-forms/${applicationFormId}/personal-details`);

      case ILLUSTRATION_DETAILS_TYPE_REPLACEMENT:
        return `/illustration-replacement/${referenceNo}`;

      case "Active":
        return `/applications/${referenceNo}`;

      case "New":
        return toV1(
          "/application-forms/submitting-an-online-application" +
            (id ? `?kfi_request=${id}` : "")
        ); // id is kfi_request id in DB

      default:
        return `/applications/${referenceNo}`;
    }
  },
  applicationDetails: ({ referenceNo }: { referenceNo: string }) => {
    return `/applications/${referenceNo}`;
  },
  applicationFormPdf: ({
    applicationFormId,
  }: {
    applicationFormId: string;
  }) => {
    return toV1(
      `/application-forms/${applicationFormId}/download_pdf_after_submit`
    );
  },
  applications: ({ filter }: { filter: string }) => {
    return filter ? `/applications?filter=${filter}` : "/applications";
  },
  changePassword: () => "/change-password",
  clientDetails: ({ clientId, type }: { clientId: string; type: string }) => {
    if (!clientId) {
      return `/new-client/client-details`;
    }

    switch (type) {
      case CLIENT_DETAILS_TYPE_UPDATE:
        return `/clients/${clientId}/client-details`;

      case CLIENT_DETAILS_TYPE_NEW:
        return `/new-client/${clientId}/client-details`;
    }
  },
  clients: () => "/clients",
  client: ({ clientId }: { clientId: string }) => `/clients/${clientId}`,
  dashboard: () => "/dashboard",
  email: ({ email, subject }: { email: string; subject: string }) => {
    if (subject) {
      return `mailto:${email}?subject=${subject}`;
    }
    return `mailto:${email}`;
  },
  forgotPassword: () => "/forgot-password",
  furtherAdvances: () => "/further-advances",
  furtherAdvanceDetails: ({ requestId }: { requestId: string }) => {
    return `/further-advances/${requestId}`;
  },
  illustration: ({
    clientId,
    illustrationId,
  }: {
    clientId: string;
    illustrationId: string;
  }) => {
    return `/clients/${clientId}/illustration-summary/${illustrationId}`;
  },
  replacementIllustration: ({
    referenceNo,
    illustrationId,
  }: {
    referenceNo: string;
    illustrationId: string;
  }) => {
    return `/illustration-replacement/${referenceNo}/summary/${illustrationId}`;
  },
  amendmentIllustration: ({
    referenceNo,
    illustrationId,
  }: {
    referenceNo: string;
    illustrationId: string;
  }) => {
    return `/illustration-amendment/${referenceNo}/summary/${illustrationId}`;
  },
  lendingCheck: ({ clientId }: { clientId: string }) => {
    return clientId ? `/new-client/${clientId}` : "/new-client";
  },
  lendingCriteria: () =>
    "https://www.canadalife.co.uk/document-library/?Solutions[]=Home%20Finance&keyword=lending&includeAdviser=true&limit=15&offset=0",
  loanDetails: ({ clientId, type }: { clientId: string; type: string }) => {
    switch (type) {
      case LOAN_DETAILS_TYPE_UPDATE:
        return `/clients/${clientId}/loan-details`;

      case LOAN_DETAILS_TYPE_NEW:
        return `/new-client/${clientId}/loan-details`;
    }
  },
  login: ({ returnPath }: { returnPath?: string }) => {
    if (returnPath) {
      return `/login?returnPath=${encodeURIComponent(returnPath)}`;
    }
    return "/login";
  },
  logout: () => {
    const base = `${env("APPLICATIONS_APP")}/logout_from_v2`;
    const redirect = `${env("FRONTEND_URL")}/login?action=logout`;

    return `${base}?redirect=${encodeURIComponent(redirect)}`;
  },
  logoutAdmin: ({ code }: { code: string }) => {
    const base = `${env("APPLICATIONS_APP")}/logout_from_v2`;
    const redirect = `${env("FRONTEND_URL")}/login-admin?code=${code}`;

    return `${base}?redirect=${encodeURIComponent(redirect)}`;
  },
  unlock: () => "/login?action=unlock",
  profile: () => "/profile",
  products: ({
    clientId,
    queryString = "",
  }: {
    clientId: string;
    queryString: string;
  }) => {
    return `/clients/${clientId}/products${queryString}`;
  },
  productDetails: ({
    clientId,
    productId,
    queryString = "",
  }: {
    clientId: string;
    productId: string;
    queryString: string;
  }) => {
    return `/clients/${clientId}/products/${productId}${queryString}`;
  },
  propertyDetails: ({ clientId, type }: { clientId: string; type: string }) => {
    switch (type) {
      case PROPERTY_DETAILS_TYPE_UPDATE:
        return `/clients/${clientId}/property-details`;

      case PROPERTY_DETAILS_TYPE_NEW:
        return `/new-client/${clientId}/property-details`;
    }
  },
  replacementLoanDetails: ({ referenceNo }: { referenceNo: string }) =>
    `/illustration-replacement/${referenceNo}/loan-details`,
  amendmentLoanDetails: ({ referenceNo }: { referenceNo: string }) =>
    `/illustration-amendment/${referenceNo}/loan-details`,
  replacementProducts: ({
    referenceNo,
    queryString = "",
  }: {
    referenceNo: string;
    queryString: string;
  }) => {
    return `/illustration-replacement/${referenceNo}/products${queryString}`;
  },
  amendmentProducts: ({
    referenceNo,
    queryString = "",
  }: {
    referenceNo: string;
    queryString: string;
  }) => {
    return `/illustration-amendment/${referenceNo}/products${queryString}`;
  },
  replacementProductDetails: ({
    referenceNo,
    productId,
    queryString = "",
  }: {
    referenceNo: string;
    productId: string;
    queryString: string;
  }) =>
    `/illustration-replacement/${referenceNo}/products/${productId}${queryString}`,
  amendmentProductDetails: ({
    referenceNo,
    productId,
    queryString = "",
  }: {
    referenceNo: string;
    productId: string;
    queryString: string;
  }) => {
    return `/illustration-amendment/${referenceNo}/products/${productId}${queryString}`;
  },
  sessionExpired: ({ returnPath }: { returnPath?: string }) => {
    if (returnPath) {
      return `/login?action=sessionExpired&returnPath=${encodeURIComponent(
        returnPath
      )}`;
    }
    return "/login?action=sessionExpired";
  },
};

const linkFor = (type: string, args: object = {}) => {
  const resolver = linksMap[type];

  if (!resolver) throw new Error(`Unrecognised link type provided (${type})`);

  return resolver(args);
};

export default linkFor;
