const reducer = (state, action) => {
  switch (action.type) {
    case "SET_MENU_OPEN":
      return {
        ...state,
        menuIsOpen: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
